import Navigation from './Navigation'
import SearchBar from './SearchBar'
import User from './User'

export default function TopBar({ hasSearchBar }) {
  return (
    <header className='flex justify-between px-8 py-8 mb-2 sticky top-0'>
      <section className='flex items-center justify-between w-1/3'>
        <Navigation />
        {hasSearchBar ? <SearchBar /> : null}
      </section>
      <User />
    </header>
  )
}
